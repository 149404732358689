.DateRangePicker {
  /*margin-left: 2rem;*/
width: 100%;
}
.DateRangePickerInput {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: rgba(26, 56, 82, 1);
  padding: 0.3rem;
  border: 0.2px solid #DEDEDE;
}
.DateInput {
  border-radius: 0.5rem;
  font-family: `'avenir next', avenir, 'century gothic', sans-serif`;
  font-size: 1.25rem;
  color: rgba(26, 56, 82, 1);
  width: 100%;
  font-weight: 700;
}
.DateInput_displayText {
  padding: 3px 8px 1px;
}
.DateInput_displayText__focused {
  background: rgba(26, 56, 82, 1);
  color: rgba(26, 56, 82, 1);
}
.CalendarDay__selected-start,
.CalendarDay__selected-end,
.CalendarDay__selected {
  background: rgba(26, 56, 82, 1) !important;
  color: #fff !important;
}
.CalendarDay__selected_span {
  background: rgba(26, 56, 82, 1) !important;
  color: #fff !important;
}
.DayPickerKeyboardShortcuts__show--bottom-right {
}
